import React from "react";
import { REVELATIONS } from "../data/homepage";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

function Revelation() {
  return (
    <div className="flex flex-col w-[100vw] overflow-x-hidden comic-background">
      <div className="bg-[#00000060]">
        <div className="flex fixed w-full z-[20] flex-row p-1 px-2  bg-[#000000da] h-[50px] items-center justify-between">
          <Link to={"/"}>
            <div className="font-bold  text-[30px]  w-fit max-w-[200px]">
              <img
                src={require("../assets/navbarlogo.png")}
                className="border-[2px] border-black w-[100px] h-[40px] sm:scale-100 scale-[0.8]"
                alt=""
              />
            </div>
          </Link>
          <div
            className="flex flex-row items-center gap-5 text-[14px] sm:text-[20px]"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <img src={require(".././assets/christwhitepng.png")} className="h-[50px]" alt="" />
          </div>
        </div>
        <div className="flex flex-col justify-center flex-1 py-10 mt-10">
          <div className="text-center bangers max-h-[300px] items-center hidden xs:flex justify-center py-10 mb-2">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              className=" color-animation bangers flex-row items-baseline flex text-[200px]  drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,1)]"
            >
              REVELATIONS
            </div>
          </div>

          <div className="text-center bangers max-h-[300px] items-center flex xs:hidden justify-center py-10 mb-4">
            <div
              data-aos="fade-down"
              data-aos-duration="1000"
              className=" color-animation bangers flex-row items-baseline flex text-[70px]  drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,1)]"
            >
              REVELATIONS
            </div>
          </div>

          <div className="flex sm:flex-row flex-col mx-3 md:mx-10 text-white gap-[20px]">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="bg-[#00000080] px-5  comic font-semibold text-white comic w-full md:w-[70%] mx-auto text-[14px] sm:text-[20px] text-justify"
            >
              “Revelations” is an Intra-departmental fest that is organized by
              the Department of Computer Science for the Post graduate students
              every year. The objective of this event is to provide a platform
              for the students to show case their talents in technical as well
              as non-technical events.
              <br />
              <br />
              The students were divided into multiple groups. In and around
              every event, there are about 300+ students competing against each
              other. The fest has 10 events in total. The preliminary rounds for
              all the events are conducted from the during the months of March
              and April
            </div>
            <div className="flex flex-col gap-2 bg-[#00000080] p-3 md:w-[30%]"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
              <div className="bangers text-[30px] border-b-[2px] border-white">
                Our Legacy . . .
              </div>
              <div className="flex flex-col gap-2">
                {REVELATIONS.legacyLinks.map((node: any) => (
                  <a
                    href={node.link}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold hover:underline underline-offset-2 px-2 comic border-transparent border-[2px] hover:border-red-500 border-l-[15px] hover:border-l-[15px]"
                  >
                    {node.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className=" mx-auto w-[70%] flex flex-row gap-1 items-center">
          <div className="min-h-[2px]  bg-gray-300  flex-1"></div>
          <div className="min-h-[10px] min-w-[10px] px-3 bangers text-white text-[30px] bg-[#00000080] ">
            What Now ?
          </div>
          <div className="min-h-[2px] bg-gray-300  flex-1"></div>
        </div>

        <div className="flex items-center justify-center w-full ">
          <Link to={"/home"}>
            <div className="p-5  retro-border bangers  md:w-fit text-[20px] md:text-[50px] my-10 px-10 bg-red-500 text-white hover:bg-white hover:text-red-500">  
              Revelations 2024
            </div>
          </Link>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Revelation;
