import React from 'react'

function Footer() {
  return (
    <div className='flex flex-col justify-center w-full'>
          <div className='text-center text-white comic text-[12px] flex justify-center bg-[#000000b7]'>
          Website by<strong className='ml-2 text-red-500'>Aravind Nandakumar</strong>
        </div>
        <div className='flex flex-row p-1 text-[12px] items-center px-2 py-1 gap-2  bg-[#000000ec] justify-center'>
            <img className='bg-blend-color-burn' src={require("../assets/christlogo.png")} width={30} height={30}  alt="" />
            <div className='text-white comic'>{`CHRIST (Deemed to be university) Department of Computer Science`}
            </div>
        </div>
    </div>
  )
}

export default Footer
