export const  REVELATIONS ={

    legacyIntroduction:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum laudantium! Recusandae laborum repudiandae voluptatum! Quod in nemo velit aut, nihil provident deserunt adipisci quos excepturi reprehenderit obcaecati illo inventore.    Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum laudantium! Recusandae laborum repudiandae voluptatum! Quod in nemo velit aut, nihil provident deserunt adipisci quos excepturi reprehenderit obcaecati illo inventore.",

    legacyLinks:[
        {name:"Revelations 2023",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/view-pdf/revelations-2023#brd-crmp-depp_new"},
        {name:"Revelations 2020",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/revelations-2020#brd-crmp-depp_new"},
        {name:"Revelations 2019",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/revelations-2019#brd-crmp-depp_new"},
        {name:"Revelations 2018",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/revelations-2018#brd-crmp-depp_new"},
        {name:"Revelations 2017",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/revelations-2017#brd-crmp-depp_new"},
        {name:"Revelations 2016",link:"https://christuniversity.in/departments/main%20campus/school%20of%20sciences/computer%20science/festivals/revelations-2016#brd-crmp-depp_new"},
        
    ]
} 